import { ErrorBoundary } from '@sentry/react';
import ErrorFallback from './components/Error/ErrorFallback';
import Simulator from './components/Simulation/Simulator';
import { SimulationProvider } from './contexts/SimulationContext';

const App: React.FC = () => {
  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <SimulationProvider>
        <Simulator />
      </SimulationProvider>
    </ErrorBoundary>
  );
};

export default App;
