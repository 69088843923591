import { getBaseUrl } from '../../helpers/methods';
import TransactionRowContainer from '../Containers/TransactionRowContainer';
import ImageWithTextFallback from '../Shared/ImageWithFallback';

interface ISignatureCardProps {
  sourceUrl: string;
  logo: string;
}

const SignatureCard: React.FC<ISignatureCardProps> = ({ sourceUrl, logo }) => {
  const baseUrl = new URL(sourceUrl).hostname.split('.').slice(-2, -1)[0];
  const hostNameFirstAndLastLetters = `${baseUrl[0]}${baseUrl.slice(-1)}`;
  return (
    <TransactionRowContainer loading={false}>
      <div className="flex flex-row items-center justify-between h-full">
        <div className="flex flex-row items-center">
          <ImageWithTextFallback
            className="h-[32px] w-[32px]"
            src={logo}
            fallbackText={hostNameFirstAndLastLetters}
          />
          <div className="ml-1 overflow-hidden whitespace-nowrap text-overflow-ellipsis font-ClashDisplay text-xl font-medium">
            {sourceUrl ? getBaseUrl(sourceUrl) : 'Unknown'}
          </div>
        </div>
      </div>
    </TransactionRowContainer>
  );
};

export default SignatureCard;
