import {
  EIPTransactionDictionary,
  EIPTransactionTypes,
  ErrorTypes,
  ScamTypesEnum,
  SignatureTypes,
} from '../enums/application.enums';
import { ethereumBlue, polygon } from '../icons/cryptoIcons';

export const NETWORK_INFO: any = {
  '0x1': {
    decimals: 18,
    name: 'Eth',
    symbol: 'ETH',
    network: 'Ethereum Mainnet',
    icon: ethereumBlue,
    explorer: 'https://etherscan.io/',
  },
  '0x89': {
    decimals: 18,
    name: 'Matic',
    symbol: 'MATIC',
    network: 'Polygon Mainnet',
    icon: polygon,
    explorer: 'https://polygonscan.com/',
  },
  '0x5': {
    decimals: 18,
    name: 'Goerli Eth',
    symbol: 'GoerliETH',
    network: 'Goerli Testnet',
    icon: ethereumBlue,
    explorer: 'https://goerli.etherscan.io/',
  },
  '0xa': {
    decimals: 18,
    name: 'Optimism Eth',
    symbol: 'OpETH',
    network: 'Optimism Mainnet',
    icon: ethereumBlue,
    explorer: 'https://optimistic.etherscan.io/',
  },
  '0xa4b1': {
    decimals: 18,
    name: 'Arbitrum Eth',
    symbol: 'ArbETH',
    network: 'Arbitrum Mainnet',
    icon: ethereumBlue,
    explorer: 'https://arbiscan.io/',
  },
  '0x2105': {
    decimals: 18,
    name: 'Base ETH',
    symbol: 'BaseETH',
    network: 'Base Mainnet',
    icon: ethereumBlue,
    explorer: 'https://basescan.org/',
  },
};

export const NetworkDecToHexMapping = new Map<number, string>([
  [1, '0x1'],
  [145, '0x89'],
  [5, '0x5'],
  [10, '0xa'],
  [42161, '0xa4b1'],
]);

export const TRANSACTION_SIMULATION_TYPES = [
  EIPTransactionDictionary.ERC20_APPROVE,
  EIPTransactionDictionary.ERC721_APPROVE,
  EIPTransactionTypes.ERC20,
  EIPTransactionTypes.ETH,
  EIPTransactionTypes.ERC721,
  EIPTransactionTypes.ERC1155,
];

export const WARNING_TYPES = [
  EIPTransactionTypes.ETH_SIG,
  ...Object.values(ScamTypesEnum),
];

export const NOSIM_SIGNATURE_TYPES = Object.values(SignatureTypes);

export const FIRE_SUPPORT_URL =
  'https://joinfire.zendesk.com/hc/en-us/requests/new?ticket_form_id=13514461731483';

export const ERROR_OVERRIDE_TYPES = [
  ErrorTypes.NOT_VERIFIED,
  ErrorTypes.GENERAL_ERROR,
];
