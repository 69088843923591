import {
  NOSIM_SIGNATURE_TYPES,
  TRANSACTION_SIMULATION_TYPES,
} from '../../helpers/constants/application.constants';
import {
  DeepSimulationType,
  ErrorTypes,
} from '../../helpers/enums/application.enums';
import { EIPGlobalInformation } from '../../helpers/interfaces/dataTypes.interface';
import WalletEffectsHeader from '../Shared/WalletEffects';

interface IInteractionInfoViewProps {
  eipGlobalInformation: EIPGlobalInformation;
  transactionType: string;
}

//Renders the necessary component for the Interaction info header
const InteractionInfoView: React.FC<IInteractionInfoViewProps> = ({
  eipGlobalInformation,
  transactionType,
}) => {
  //evaluates to true if the sim failed but we want a 'soft' error state instead of the error screen
  const simulationFailed = !!(
    eipGlobalInformation.error &&
    [ErrorTypes.NOT_VERIFIED, ErrorTypes.GENERAL_ERROR].includes(
      eipGlobalInformation.error.title
    )
  );

  if (
    NOSIM_SIGNATURE_TYPES.includes(transactionType as any) ||
    transactionType === DeepSimulationType.ENS_SIGNATURE_REQUEST
  ) {
    return (
      <WalletEffectsHeader
        loading={eipGlobalInformation.loading}
        isSimulation={false}
      />
    );
  } else if (
    TRANSACTION_SIMULATION_TYPES.includes(transactionType as any) ||
    simulationFailed
  ) {
    return <WalletEffectsHeader loading={eipGlobalInformation.loading} />;
  } else {
    return <></>;
  }
};

export default InteractionInfoView;
