import clsx from 'clsx';

interface ILabeledDescriptionProps {
  icon?: JSX.Element;
  description: string;
  useChildVariant?: boolean;
  children?: React.ReactNode;
}

const LabeledDescription: React.FC<ILabeledDescriptionProps> = ({
  icon,
  description,
  useChildVariant,
  children,
}) => {
  return (
    <div className="flex justify-start items-center py-3 w-full">
      <div className="mr-3">{useChildVariant ? children : icon}</div>
      <div
        className={clsx(
          useChildVariant &&
            'text-[16px] leading-snug tracking-wide font-secondary-800 font-Manrope'
        )}
      >
        {description}
      </div>
    </div>
  );
};

export default LabeledDescription;
