import { useContext } from 'react';
import { SimulationContext } from '../../../contexts/SimulationContext';
import { NetworkLogo } from '../../../helpers/icons/icons';
import { formatCurrency, formatUSD } from '../../../helpers/methods';

export interface GasEstimateProps {
  hexChainId: string;
  usdPrice: number;
  value: string;
  decimals: number;
  className?: string | undefined;
}

const GasEstimate: React.FC<GasEstimateProps> = ({
  hexChainId,
  usdPrice,
  value,
  decimals,
  className,
}) => {
  const { eipGlobalInformation } = useContext(SimulationContext);
  //If USD price not available fallback to token value
  const formattedValue =
    usdPrice !== -1 ? formatUSD(usdPrice) : formatCurrency(value, decimals);
  return (
    <div className={className}>
      {!eipGlobalInformation.isFireWalletSimulation && (
        <>
          <div className="font-ClashDisplay text-secondary-800 text-2xl font-medium">
            Estimated Fee
          </div>
          <div className="flex flex-row mt-2 items-center">
            <NetworkLogo
              className="mr-1"
              chainId={hexChainId === '0x89' ? hexChainId : '0x1'}
              direction="left"
            />
            <div className="font-ClashDisplay font-medium text-lg">
              {formattedValue}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GasEstimate;
