import { ParsedMessage } from '@spruceid/siwe-parser';
import { isHexPrefixed } from 'ethereumjs-util';
import { ethers } from 'ethers';
import {
  PersonalSignatureRiskReasonEnum,
  RiskSeverityEnum,
  SecurityWarningSourceEnum,
  TrustLevelEnum,
} from './enums/application.enums';
import {
  RiskFlag,
  SIWEMessage,
  SecurityRiskData,
} from './interfaces/dataTypes.interface';
import { getBaseUrl } from './methods';

/**
 * This function strips the hex prefix from a string if it has one.
 *
 * @param str - The string to check
 * @returns The string without the hex prefix
 */
const stripHexPrefix = (str: string) => {
  return isHexPrefixed(str) ? str.slice(2) : str;
};

/**
 * This function converts a hex string to text if it's not a 32 byte hex string.
 *
 * @param hex - The hex string to convert to text
 * @returns The text representation of the hex string
 */
const msgHexToText = (hex: string): string => {
  try {
    const stripped = stripHexPrefix(hex);
    const buff = Buffer.from(stripped, 'hex');
    return buff.length === 32 ? hex : buff.toString('utf8');
  } catch (e) {
    console.error(e);
    return hex;
  }
};

/**
 * Parses parts from RFC 3986 authority from EIP-4361 `domain` field.
 *
 * @param domain - input string
 * @param originProtocol - implied protocol from origin
 * @returns parsed parts
 */
const parseDomainParts = (
  domain: string,
  originProtocol: string
): {
  username?: string;
  hostname: string;
  port?: string;
} => {
  if (domain.match(/^(http:\/\/|https:\/\/)/)) {
    return new URL(domain);
  }
  return new URL(`${originProtocol}//${domain}`);
};

/**
 * Validates origin of a Sign-In With Ethereum (SIWE)(EIP-4361) request.
 * As per spec:
 * hostname must match.
 * port and username must match if specified.
 * Protocol binding and full same-origin are currently not performed.
 *
 * @param req - Signature request
 * @returns true if origin matches domain; false otherwise
 */
const isValidSIWEOrigin = (req: {
  origin: string;
  siwe: SIWEMessage;
}): boolean => {
  const DEFAULT_PORTS_BY_PROTOCOL = {
    'http:': '80',
    'https:': '443',
  } as Record<string, string>;

  try {
    const { origin, siwe } = req;

    // origin = scheme://[user[:password]@]domain[:port]
    // origin is supplied by environment and must match domain claim in message
    if (!origin || !siwe?.parsedMessage?.domain) {
      return false;
    }

    const originParts = new URL(origin);
    const domainParts = parseDomainParts(
      siwe.parsedMessage.domain,
      originParts.protocol
    );

    if (
      domainParts.hostname.localeCompare(originParts.hostname, undefined, {
        sensitivity: 'accent',
      }) !== 0
    ) {
      return false;
    }

    if (domainParts.port !== '' && domainParts.port !== originParts.port) {
      // If origin port is not specified, protocol default is implied
      return (
        originParts.port === '' &&
        domainParts.port === DEFAULT_PORTS_BY_PROTOCOL[originParts.protocol]
      );
    }

    if (
      domainParts.username !== '' &&
      domainParts.username !== originParts.username
    ) {
      return false;
    }

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

/**
 * This function intercepts a sign message, detects if it's a
 * Sign-In With Ethereum (SIWE)(EIP-4361) message, and returns an object with
 * relevant SIWE data.
 *
 * {@see {@link https://eips.ethereum.org/EIPS/eip-4361}}
 *
 * @param msgParams - The params of the message to sign
 * @param msgParams.data - The data of the message to sign
 * @returns An object with the relevant SIWE data
 */
export const checkEIP4361 = (msgParams: { data: string }): SIWEMessage => {
  try {
    const { data } = msgParams;
    const message = msgHexToText(data);
    const parsedMessage = new ParsedMessage(message);

    return {
      isSIWEMessage: true,
      parsedMessage,
    };
  } catch (error) {
    //SIWE parser will throw if the message was invalid. Ignore the error and return false.
    return {
      isSIWEMessage: false,
      parsedMessage: null,
    };
  }
};

/**
 * Converts a personal_sign data message to UTF-8 and back to see if it's a human readable string or not
 *
 * @param signingData - The raw message portion of a personal_sign request in string format
 * @returns A boolean indicating whether or not the message is human readable
 */
export const isPersonalSignMessageHumanReadable = (
  signingData: string
): boolean => {
  // Attempt to normalize hex signing data to a UTF-8 string message.
  if (signingData.startsWith('0x')) {
    let possibleMessageString: string | undefined;
    try {
      possibleMessageString = ethers.utils.toUtf8String(signingData);
    } catch (err) {}

    // If the hex was parsable as UTF-8 and re-converting to bytes in a hex string produces the identical output, accept it as a valid string and set the interpreted data to the UTF-8 string.
    if (
      possibleMessageString !== undefined &&
      ethers.utils
        .hexlify(ethers.utils.toUtf8Bytes(possibleMessageString))
        .toLowerCase() === signingData.toLowerCase()
    ) {
      return true;
    }
  }
  return false;
};

/**
 * This function evaluates the signature payload contained within the personal_sign RPC request and returns a risk level and any warning flags that should be displayed to the user.
 * It is responsible for identifying the following risks:
 * 1. The EIP type of the signature request (EIP-191 vs EIP-4361)
 * 2. An EIP-191 signature request that is not in a human readable format which is capable of signing a signature that can move funds
 * 3. An EIP-4361 signature request that is not from the expected origin
 *
 *
 * @param signatureRequestData - The RPC request
 * @param sourceUrl - The URL of the website the user is currently on
 * @returns A SecurityRiskData object containing data outlining the risk level of this request
 */
export const getPersonalSignatureRiskData = ({
  signatureRequestData,
  sourceUrl,
}: {
  signatureRequestData: string[];
  sourceUrl: string;
}) => {
  let riskData: SecurityRiskData = {
    globalTrustLevel: TrustLevelEnum.HIGH,
  };

  const message = signatureRequestData[0];

  //SIWE steps per EIP-4361
  // verify message format
  const siweMessage = checkEIP4361({ data: message });

  // verify SIWE request origin
  if (siweMessage.isSIWEMessage) {
    const isValidOrigin = isValidSIWEOrigin({
      origin: sourceUrl,
      siwe: siweMessage,
    });

    //dangerous signature case. If siwe origin checks fail, something sketchy is going on. Either a gross oversight by the dApp developer or (more likely) a scam attempt. Advise the user to reject the signature.
    if (!isValidOrigin) {
      const invalidOriginFlag: RiskFlag = {
        severity: RiskSeverityEnum.HIGH,
        userWarningMessage: `This request appears to be from ${getBaseUrl(
          sourceUrl
        )} but is actually from ${
          siweMessage.parsedMessage.domain
        } and may be a phishing attempt.`,

        source: SecurityWarningSourceEnum.FIRE,
        reasons: [PersonalSignatureRiskReasonEnum.SIWE_ORIGIN_MISMATCH],
      };

      riskData = {
        globalTrustLevel: TrustLevelEnum.LOW,
        flags: {
          bannerView: [],
          interactionInfoView: [],
          walletChangesView: [invalidOriginFlag],
        },
      };
    }
  }
  // if the message is not EIP-4361 compliant, it is EIP-191 which does have the potential to have the user sign an arbitray message. Additional verification is necessary to ensure it's just a human readable message and not a signature authorizing a transaction
  else {
    const isHumanReadableSig = isPersonalSignMessageHumanReadable(message);

    //risky signature case. If a signature is not siwe format and not human readable, it could be used to sign a valid signature capable of moving funds (e.g. the x2y2 NFT listing case 😔)
    // Warn the user that we couldn't decode this to something human readable, don't sign if you don't trust the source
    if (!isHumanReadableSig) {
      const obfuscatedMessageFlag: RiskFlag = {
        severity: RiskSeverityEnum.MEDIUM,
        source: SecurityWarningSourceEnum.FIRE,
        reasons: [PersonalSignatureRiskReasonEnum.OBFUSCATED_MESSAGE],
        userWarningMessage: `This signature request is not in a human readable format. Please ensure you trust this dApp prior to signing.`,
      };

      riskData = {
        globalTrustLevel: TrustLevelEnum.MEDIUM,
        flags: {
          bannerView: [],
          interactionInfoView: [],
          walletChangesView: [obfuscatedMessageFlag],
        },
      };
    }
  }

  return riskData;
};
