let env = 'production';

//TODO: look into whatever this cloudflare environment variable issue is
if (
  process.env.REACT_APP_STAGE === 'staging' ||
  window.location.href.includes('staging') // fix for cloudflare environment variable issue
) {
  env = 'staging';
} else if (
  process.env.REACT_APP_STAGE === 'production' ||
  window.location.href.includes('app.joinfire.xyz') ||
  window.location.href.includes('fly-lambda.fly.dev')
) {
  env = 'production';
} else if (process.env.REACT_APP_STAGE === 'development') {
  env = 'development';
}

export const LAMBDA_URL =
  env === 'development'
    ? 'http://localhost:3001'
    : env === 'production'
    ? 'https://fly-lambda.fly.dev'
    : 'https://lambda-be-staging.fly.dev';

export const getFullURL = (url: string) => {
  return `${LAMBDA_URL}/${url}`;
};
