/* eslint-disable @typescript-eslint/no-non-null-assertion */
import clsx from 'clsx';
import { useContext } from 'react';
import { SimulationContext } from '../../contexts/SimulationContext';
import {
  RiskSeverityEnum,
  SignatureTypes,
  TrustLevelEnum,
} from '../../helpers/enums/application.enums';
import {
  EyeIcon,
  QuestionIcon,
  StopIcon,
  WarningIcon,
} from '../../helpers/icons/icons';
import {
  ISignatureDescription,
  ISignatureDetail,
} from '../../helpers/interfaces/dataTypes.interface';
import LabeledDescription from '../Shared/LabeledDescription';

interface ISimpleSignatureProps {
  type: SignatureTypes;
}

const SIGNATURE_COMPONENT_DETAILS = new Map<SignatureTypes, ISignatureDetail>([
  [
    SignatureTypes.EIP_4361_PERSONAL_SIGN,
    {
      isSafe: true,
      descriptionList: [
        {
          icon: EyeIcon,
          description:
            'This dApp is requesting a Sign In With Ethereum (SIWE) message signature.',
        },
      ],
    },
  ],
  [
    SignatureTypes.EIP_191_PERSONAL_SIGN,
    {
      isSafe: true,
      descriptionList: [
        {
          icon: EyeIcon,
          description:
            'This dApp is requesting your signature on a personal sign message.',
        },
        {
          icon: StopIcon,
          description:
            'This signature type is often used to prove ownership of a wallet address.',
        },
      ],
    },
  ],
  [
    SignatureTypes.UNKNOWN,
    {
      isSafe: false,
      descriptionList: [
        {
          icon: QuestionIcon,
          description: "We don't recognize this signature.",
        },
        {
          icon: WarningIcon,
          description:
            'Make sure you trust this dApp before signing this message.',
        },
      ],
    },
  ],
]);

const SimpleSignature: React.FC<ISimpleSignatureProps> = ({ type }) => {
  const { eipGlobalInformation } = useContext(SimulationContext);

  //If for some reason this errors at runtime due to the non-null assertion, we want it to
  const signatureDetail =
    SIGNATURE_COMPONENT_DETAILS.get(type) ??
    SIGNATURE_COMPONENT_DETAILS.get(SignatureTypes.UNKNOWN)!;

  let warning;
  const displayWarning =
    eipGlobalInformation.securityRiskData.globalTrustLevel !==
      TrustLevelEnum.HIGH &&
    eipGlobalInformation.securityRiskData.flags?.walletChangesView;

  if (displayWarning) {
    warning = (
      <div className="mt-5">
        <div
          className={clsx(
            'tracking-wide font-semibold text-center font-Manrope',
            eipGlobalInformation.securityRiskData.flags?.walletChangesView[0]
              .severity === RiskSeverityEnum.HIGH && 'text-red-600'
          )}
        >
          Warning:{' '}
          {
            eipGlobalInformation.securityRiskData.flags?.walletChangesView[0]
              .userWarningMessage
          }
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="p-3">
        {signatureDetail.descriptionList.map(
          (signatureType: ISignatureDescription, index: number) => {
            return (
              <LabeledDescription
                key={index}
                icon={signatureType.icon}
                description={signatureType.description}
              />
            );
          }
        )}
        {displayWarning && warning}
      </div>
    </div>
  );
};

export default SimpleSignature;
