interface ILayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<ILayoutProps> = ({ children }) => {
  return (
    <div className={`h-screen no-scrollbar overflow-y-auto bg-[#FAF7FF]`}>
      {children}
    </div>
  );
};

export default Layout;
