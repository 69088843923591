import { ethers } from 'ethers';
import { contracts } from './constants/NftContracts';
import { NETWORK_INFO } from './constants/application.constants';
import { exclamation_triangle_filled } from './icons/icons';

export const contractBannerColor = (signature: boolean): string => {
  return signature ? 'bg-error-800' : 'bg-secondary-800';
};

export const contractTextColor = (signature: boolean): string => {
  return signature ? 'text-secondary-800' : 'text-white';
};

export const warningIcon = (signature: boolean): JSX.Element => {
  return signature ? exclamation_triangle_filled() : <></>;
};

export const formatUSD = (value: number) => {
  const formatValue = (num: number) => {
    return `$${num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  if (value === 0 || !value) {
    return '$0.00';
  }
  if (value < 0.01) {
    return '< $0.01';
  }
  if (value.toString().includes('e')) {
    // formatting to remove scientific notation
    // so instead of 1e-6 we get 0.000001
    const fixedValue = parseFloat(
      value.toFixed(parseInt(value.toString().split('-')[1]))
    );
    return formatValue(fixedValue);
  }
  return formatValue(value);
};

export const capitalizeString = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const formatCurrencyWithoutRounding = (
  value: string,
  decimals?: number | undefined
) => {
  const tokenDecimals = decimals || 18;

  const max256 = parseInt(
    '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
    16
  ).toString(16);

  const max160 = parseInt(
    '0xffffffffffffffffffffffffffffffffffffffff',
    16
  ).toString(16);

  if (
    parseInt(value).toString(16) === max256 ||
    parseInt(value).toString(16) === max160
  ) {
    return 'Infinite';
  }

  const result = parseInt(value) / Math.pow(10, tokenDecimals);

  if (result.toString().includes('e')) {
    // formatting to remove scientific notation
    // so instead of 1e-6 we get 0.000001
    return result.toFixed(parseInt(result.toString().split('-')[1]));
  }

  return result;
};

export const formatCurrency = (
  value: string,
  decimals?: number | undefined
) => {
  const tokenDecimals = decimals || 18;
  const max256 = parseInt(
    '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
    16
  ).toString(16);

  const max160 = parseInt(
    '0xffffffffffffffffffffffffffffffffffffffff',
    16
  ).toString(16);

  if (
    parseInt(value).toString(16) === max256 ||
    parseInt(value).toString(16) === max160
  ) {
    return 'Infinite';
  }

  const result = parseInt(value) / Math.pow(10, tokenDecimals);

  if (result === 0) {
    return 0;
  }

  if (result < 0.001) {
    return '< 0.001';
  }

  const roundedResult = Math.round(result * 1000) / 1000;

  return roundedResult;
};

export const reduceAddress = (address: string) => {
  if (address.length < 12) {
    return address.charAt(0).toUpperCase() + address.slice(1);
  }
  try {
    if (address !== 'validators') {
      return address.slice(0, 4) + '...' + address.slice(-4);
    }
    return address.charAt(0).toUpperCase() + address.slice(1);
  } catch (error) {
    return '';
  }
};

export const reduceName = (name: string | undefined | null) => {
  if (!name) return undefined;

  if (name.length > 20) {
    return `${name.slice(0, 21)}...`;
  } else return name;
};

export const getBlockExplorerUrl = (input: string, chainId: string): string => {
  return NETWORK_INFO[chainId]
    ? `${NETWORK_INFO[chainId].explorer}address/${input}`
    : '';
};

export const getContractName = (
  address: string,
  network: string
): string | undefined | null => {
  if (!address) return null;
  if (address.substring(0, 2) !== '0x') return address; //If the address was already labeled, return it

  if (!contracts[network]) {
    return null;
  }

  return contracts[network][address.toLowerCase()]
    ? contracts[network][address.toLowerCase()].name
    : null;
};

export const resolveENSName = async (
  address: string
): Promise<string | null> => {
  const provider = new ethers.providers.AlchemyProvider(
    'homestead',
    'G-Uc_pdqbIAwHnom2xALqLjaYIJbPDvN'
  );
  try {
    return address.substring(0, 2) !== '0x' //if we don't find basic address formatting just return null since we know it'll fail
      ? null
      : provider.lookupAddress(address);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getAddressLabel = async (
  address: string,
  network: string
): Promise<string | null> => {
  const addressLabel =
    getContractName(address, network) ??
    (network === '0x1' ? resolveENSName(address) : null); //only attempt to resolve ENS on mainnet
  return addressLabel;
};

export const hexToUtf8 = (hexString: string) => {
  try {
    const decoded = Buffer.from(hexString[0].slice(2), 'hex').toString('utf-8');
    return decoded.replace(/\n/g, '\n');
  } catch (e) {
    return hexString;
  }
};

//strip everything before and after the base url
export const getBaseUrl = (sourceUrl: string) => {
  return sourceUrl?.replace(/^https?:\/\/([^/]+).*$/, '$1');
};

export const SplitNetBalanceChanges = (
  netBalanceChanges: any[],
  userAddress: string
) => {
  const incomingTxs: any[] = [];
  const outgoingTxs: any[] = [];
  const validators: any[] = [];

  if (!!netBalanceChanges?.length) {
    netBalanceChanges.forEach((transaction: any) => {
      if (transaction.to === 'validators') {
        validators.push(transaction);
      } else if (
        ['erc20Approve', 'erc721Approve', 'erc1155Approve'].includes(
          transaction.type
        )
      ) {
        outgoingTxs.push({
          ...transaction,
        });
      } else if (
        transaction?.from?.toLowerCase() === userAddress.toLowerCase()
      ) {
        outgoingTxs.push(transaction);
      } else if (transaction?.to?.toLowerCase() === userAddress.toLowerCase()) {
        incomingTxs.push(transaction);
      }
    });
  }

  return { incomingTxs, outgoingTxs, validators };
};

export const parseSpenderAddress = (json: any) => {
  try {
    const data = JSON.parse(json);

    if (data && typeof data === 'object') {
      const findSpender = (obj: any): any => {
        if ('spender' in obj) {
          return obj.spender;
        } else if ('offerer' in obj) {
          return obj.offerer; // Seaport
        } else if ('trader' in obj) {
          return obj.trader; // Blur
        }

        for (const key in obj) {
          if (typeof obj[key] === 'object') {
            const result = findSpender(obj[key]);
            if (result) {
              return result;
            }
          }
        }

        return undefined;
      };

      return findSpender(data);
    }
  } catch (error) {
    //catch parsing error but ignore because it's noisy in the console and doesn't need to be handled
  }

  return undefined;
};

// Type guard to check if an array contains only strings
export const isStringArrayTypeguard = (value: any[]): value is string[] => {
  return value.every((element) => typeof element === 'string');
};
