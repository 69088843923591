import { SignatureTypes, TrustLevelEnum } from '../enums/application.enums';
import {
  SecurityRiskData,
  SimulationRequest,
} from '../interfaces/dataTypes.interface';
import { isStringArrayTypeguard } from '../methods';
import { getPersonalSignatureRiskData } from '../personalSignatureHelper';

//TODO: Because our current tx security evaluation is fragmented, this is only scoped to handle pre-simulated requests. Down the line we may want to consider consolidating all security handling into a single engine that can be used for both pre and post simulated requests
/**
 * This function calls the appropriate security risk evaluation function based on the request type and is responsible for populating our global security risk metadata.
 *
 * @param simulationRequest - The simulation request recieved from the extension
 * @returns A SecurityRiskData object containing the global trust level and any warning flags that should be displayed to the user
 */
export const getSecurityRisk = (
  simulationRequest: SimulationRequest
): SecurityRiskData => {
  switch (simulationRequest.rpcMethod) {
    case SignatureTypes.EIP_191_PERSONAL_SIGN: {
      if (isStringArrayTypeguard(simulationRequest.data)) {
        return getPersonalSignatureRiskData({
          signatureRequestData: simulationRequest.data,
          sourceUrl: simulationRequest.sourceUrl,
        });
      } else {
        throw new Error('Invalid data type for personal signature');
      }
    }
    default: {
      return {
        globalTrustLevel: TrustLevelEnum.HIGH,
      };
    }
  }
};
