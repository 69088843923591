import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  getSimulatedEipTransaction,
  getSimulatedSignature,
} from '../api/simulator';
import { getErrorInformation } from '../components/Error/errorHelper';
import GlobalLoader from '../components/Loader/GlobalLoader';
import { DEFAULT_EIP_GLOBAL_INFORMATION } from '../helpers/SimulationsHelper/dataDictionaries';
import { NETWORK_INFO } from '../helpers/constants/application.constants';
import { getSecurityRisk } from '../helpers/engines/securityEngine';
import {
  DeepSimulationType,
  EIPTransactionTypes,
  SignatureTypes,
  WalletProvidersEnum,
} from '../helpers/enums/application.enums';
import {
  DeepSimulation,
  EIPGlobalInformation,
  SimulationData,
  SimulationRequest,
  Transaction,
} from '../helpers/interfaces/dataTypes.interface';
import { getAddressLabel, parseSpenderAddress } from '../helpers/methods';
import { checkEIP4361 } from '../helpers/personalSignatureHelper';

interface ISimulationContextProps {
  eipGlobalInformation: EIPGlobalInformation;
  transactionSimulation: SimulationData | undefined;
  transactionType: string;
}

//Create global react context
const SimulationContext = React.createContext<ISimulationContextProps>(
  {} as ISimulationContextProps
);

interface ISimulationProviderProps {
  children: React.ReactNode;
}

//Set Global state
const SimulationProvider: React.FC<ISimulationProviderProps> = ({
  children,
}) => {
  const [eipGlobalInformation, setEIPGlobalInformation] =
    useState<EIPGlobalInformation>(DEFAULT_EIP_GLOBAL_INFORMATION);
  const [transactionType, setTransactionType] = useState('');

  const [transactionSimulation, setTransactionSimulation] =
    useState<SimulationData>();

  /**
   * Update global transation information
   */
  const updateEIPInformation = async (value: Partial<EIPGlobalInformation>) => {
    return setEIPGlobalInformation((prevState) => ({
      ...prevState,
      ...value,
    }));
  };

  useEffect(() => {
    window.addEventListener('message', function (event) {
      if (event.data.type === 'initialData') {
        const transactionRequest: SimulationRequest = event.data.data;
        iframeTransactionSimulation(transactionRequest);

        //respond to the extension indicating we have received the request
        window.parent.postMessage(
          {
            type: 'response',
            message: 'RPC request recieved successfully',
            payload: event.data.data,
          },
          '*'
        );
      }
    });
  });

  const iframeTransactionSimulation = async (payload: SimulationRequest) => {
    const signatureTypes = Object.values(SignatureTypes);
    const requestType = signatureTypes.includes(payload.rpcMethod as any)
      ? 'signature'
      : 'transaction';

    setDefaultStates(payload, requestType);

    if (requestType === 'signature') {
      const simulatedSignature = await getSignatureSimulation(
        payload.data as string[],
        payload.hexChainId
      );
      updateEIPInformation({
        loading: false,
      });

      setSignatureSimulatedState(simulatedSignature?.data as any);
    } else {
      await getTransactionSimulation(
        payload.data[0] as Transaction,
        payload.hexChainId
      );
      updateEIPInformation({
        loading: false,
      });
    }
  };

  const setSignatureSimulatedState = async (
    simulation: SimulationData | undefined
  ) => {
    if (!simulation) {
      return;
    }
    setTransactionSimulation(simulation);

    updateEIPInformation({
      from: simulation?.from,
    });

    updateEIPInformation({
      contractAddress: simulation?.to,
      spender: simulation?.spender,
      deepSimulationData: simulation.deepSimulationData
        ? (simulation.deepSimulationData as DeepSimulation)
        : undefined,
    });
    setTransactionData(simulation);
  };

  const setDefaultStates = (
    payload: SimulationRequest,
    requestType: 'transaction' | 'signature'
  ) => {
    const securityRiskData = getSecurityRisk(payload);
    updateEIPInformation({
      network: payload.hexChainId,
      loading: true,
      downloadId: payload.downloadId,
      sourceUrl: payload.sourceUrl,
      payload: payload,
      isFireWalletSimulation:
        payload.walletProvider === WalletProvidersEnum.FIRE_WALLET,
      securityRiskData,
    });
    if (payload.rpcMethod === 'personal_sign') {
      const signature = payload.data as any;
      const isSiweSignature = checkEIP4361({ data: signature[0] });

      setTransactionType(
        isSiweSignature.isSIWEMessage
          ? SignatureTypes.EIP_4361_PERSONAL_SIGN
          : SignatureTypes.EIP_191_PERSONAL_SIGN
      );
      updateEIPInformation({
        from: signature[1],
        type: payload.rpcMethod,
        rawData: JSON.stringify(signature, null, 2),
      });
    } else if (requestType === 'transaction') {
      const transaction = payload.data as Transaction;
      updateEIPInformation({
        from: transaction.from,
        contractAddress: transaction.to,
        rawData: JSON.stringify(transaction, null, 2),
      });
    } else if (payload.rpcMethod === 'eth_signTypedData') {
      const signature = payload.data as any;
      const typedDataJson = JSON.stringify(signature[0]);
      setTransactionType(payload.rpcMethod);
      updateEIPInformation({
        from: signature[1],
        type: payload.rpcMethod,
        spender: parseSpenderAddress(typedDataJson),
        rawData: typedDataJson,
        primaryType: 'eth_signTypedData',
      });
    } else {
      const signature = payload.data as any;
      setTransactionType(payload.rpcMethod);
      updateEIPInformation({
        from: signature[0],
        type: payload.rpcMethod,
        spender: parseSpenderAddress(signature[1]),
        rawData: JSON.stringify(JSON.parse(signature[1]), null, 2),
        primaryType: JSON.parse(signature[1]).primaryType,
      });
    }
  };

  const debugLog = (msg: any) => {
    if (
      ['development', 'staging'].includes(process.env.REACT_APP_STAGE ?? '') ||
      window.location.href.includes('staging')
    ) {
      console.log('debugging: logging API response --> ', msg);
    }
  };

  const getSignatureSimulation = async (
    signature: string[],
    network: string
  ) => {
    try {
      const parsedSignature = JSON.parse(signature[1]);

      const signatureSimulationData = {
        types: parsedSignature.types,
        primaryType: parsedSignature.primaryType,
        domain: parsedSignature.domain,
        message: parsedSignature.message,
        fromAddress: signature[0],
        network: network,
      };

      const simulation = await axios(
        getSimulatedSignature(signatureSimulationData)
      );

      debugLog(simulation);

      const tokenInfo = NETWORK_INFO[network || '0x1'];
      const errorInfo = getErrorInformation(simulation, undefined, tokenInfo);
      updateEIPInformation({ loading: false, error: errorInfo });
      return simulation;
    } catch (error: any) {
      console.error(error);
      const tokenInfo = NETWORK_INFO[network || '0x1'];
      const errorInfo = getErrorInformation(undefined, error, tokenInfo);
      updateEIPInformation({
        loading: false,
        error: errorInfo,
      });
    }
  };

  /**
   * Call API to simulate transaction
   */
  const getTransactionSimulation = async (
    transaction: Transaction,
    network: string
  ) => {
    try {
      const transactionEventData = {
        network: network,
        value: transaction?.value || '0',
        gas: transaction.gas,
        from: transaction.from,
        to: transaction?.to, //will be undefined if a contract is being deployed
        data: transaction?.data || '0x',
      };

      if (transaction?.from && transaction?.to) {
        const ensPromises = new Map<string, Promise<string | null>>([
          [transaction.from, getAddressLabel(transaction.from, network)],
          [transaction.to, getAddressLabel(transaction.to, network)],
        ]);
        updateEIPInformation({ addressLabels: ensPromises });
      } else {
        updateEIPInformation({ addressLabels: undefined });
      }

      const response = await axios(
        getSimulatedEipTransaction(transactionEventData)
      );

      debugLog(response);

      const user: string | undefined =
        localStorage.getItem('address') || response?.data?.from;
      // Type of EIP transaction (i.e. 20 | 721)
      const eipType = response?.data.type;

      setTransactionType(eipType);
      updateEIPInformation({
        from: user,
        type: eipType,
        contractAddress: response?.data.to,

        spender: response?.data.spender,
        deepSimulationData: response?.data.deepSimulationData
          ? (response.data.deepSimulationData as DeepSimulation)
          : undefined,
      });

      const tokenInfo = NETWORK_INFO[network || '0x1'];
      const errorInfo = getErrorInformation(response, undefined, tokenInfo);
      updateEIPInformation({ loading: false, error: errorInfo });
      setTransactionData(response?.data);
      return response;
    } catch (error: any) {
      console.error(error);
      const tokenInfo = NETWORK_INFO[network || '0x1'];
      const errorInfo = getErrorInformation(undefined, error, tokenInfo);
      updateEIPInformation({
        loading: false,
        error: errorInfo,
      });
    }
  };

  const setTransactionData = (response: any) => {
    setTransactionSimulation(response);
    if (
      response.deepSimulationData &&
      (response.deepSimulationData as DeepSimulation).type ===
        DeepSimulationType.ENS_SIGNATURE_REQUEST
    ) {
      setTransactionType(
        response.deepSimulationData
          ? (response.deepSimulationData as DeepSimulation).type
          : response.type
      );
      return;
    }
    setTransactionType(
      response.type === 'signature'
        ? EIPTransactionTypes.UNKNOWN
        : response.type
    );
  };

  return (
    <SimulationContext.Provider
      value={{
        eipGlobalInformation,
        transactionSimulation,
        transactionType,
      }}
    >
      {eipGlobalInformation.loading ? <GlobalLoader /> : <>{children}</>}
    </SimulationContext.Provider>
  );
};

export { SimulationContext, SimulationProvider };
