import { ethers } from 'ethers';
import { useContext } from 'react';
import { SimulationContext } from '../../contexts/SimulationContext';
import { NETWORK_INFO } from '../../helpers/constants/application.constants';
import { Transaction } from '../../helpers/interfaces/dataTypes.interface';
import { getBlockExplorerUrl } from '../../helpers/methods';
import { useAddressLabels } from '../../hooks/useAddressLabel';
import Address from '../Atoms/Address';
import FailedSimulationCard from '../Error/FailedSimulationCard';

//TODO: Right now this component is unstable and will break if a signature simulation fails.
// This is fine for now since the alternative was an error screen anyway, but should still improve this in the future
const SimulationFallback: React.FC = () => {
  const { eipGlobalInformation } = useContext(SimulationContext);
  if (
    eipGlobalInformation.type === 'signature' ||
    !eipGlobalInformation.payload
  )
    throw new Error('Invalid type');
  const payload = eipGlobalInformation.payload;
  const transaction = eipGlobalInformation.payload.data[0] as Transaction;

  const network = payload.hexChainId ?? '0x1';
  const { data: addressLabelMap, loading } = useAddressLabels(
    [(payload?.data as Transaction)?.to ?? ''],
    network
  );
  const isContractDeployment = !!(
    !transaction?.to &&
    !transaction?.value &&
    transaction?.data
  );

  //Grab relevant fields from the response object to show the user
  const includedKeys = ['to', 'value'];
  const keyValues = Object.entries(transaction);
  const filteredKeyValues = keyValues.filter(([key]) =>
    includedKeys.includes(key)
  );

  //Do some basic parsing of values we care about simplifying
  const items = filteredKeyValues.map(([label, value]) => {
    if (label === 'value') {
      const bigNumberValue = ethers.BigNumber.from(value);
      const ethValue = ethers.utils.formatEther(bigNumberValue);
      return {
        label: label,
        value: ethValue,
      };
    } else if (label === 'to') {
      const blockExplorerUrl = getBlockExplorerUrl(String(value), network);
      return {
        label: 'recipient',
        value: value,
        url: blockExplorerUrl,
      };
    } else {
      return {
        label: label,
        value: value,
      };
    }
  });

  interface IInteractionDetailProps {
    label: string;
    value: string;
    url?: string;
  }
  const InteractionDetail: React.FC<IInteractionDetailProps> = ({
    label,
    value,
    url,
  }) => {
    return (
      <div className="w-full flex flex-row py-2 px-4 justify-between items-center">
        <div className="flex flex-col">
          <div className="font-Manrope text-gray-400">{label}</div>
          {label === 'recipient' && !loading ? (
            <Address
              network={payload?.hexChainId ?? '0x1'}
              labeledAddress={addressLabelMap?.get(transaction?.to ?? '')}
              address={transaction?.to ?? ''}
            />
          ) : (
            <div className="font-Manrope text-secondary-800">{`${value} ${NETWORK_INFO[network].symbol}`}</div>
          )}
        </div>
        <div>
          {url && (
            <a href={url} target="_blank">
              <img
                className="h-[25px]"
                src="/images/external-link.svg"
                alt="external link"
              />
            </a>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="pb-4">
      <FailedSimulationCard isContractDeployment={isContractDeployment} />
      <div className="flex flex-col mx-auto">
        <div className="ml-5 font-ClashDisplay text-2xl font-medium mb-5">
          {!isContractDeployment ? 'Interaction Info' : ''}
        </div>
        <div className="w-[332px] h-fit overflow-y-auto bg-white rounded-lg drop-shadow-md flex flex-col mx-auto">
          {items?.map((item: any, index: number) => {
            return (
              <div key={index} className="border-b border-secondary-200">
                <InteractionDetail
                  label={item.label}
                  value={item.value}
                  url={item?.url}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SimulationFallback;
