import { FIRE_SUPPORT_URL } from '../../helpers/constants/application.constants';

interface IFailedSimulationCardProps {
  isContractDeployment?: boolean;
}

const FailedSimulationCard: React.FC<IFailedSimulationCardProps> = ({
  isContractDeployment,
}) => {
  return (
    <div className="w-[332px] h-[85px] rounded-lg bg-[#F19A83] mx-auto flex flex-col p-2 my-5 drop-shadow-md">
      <div className="flex flex-row mx-auto">
        <img className="mt-0.5" src="/images/cautionIcon.svg" />
        <div className="font-ClashDisplay text-secondary-800 font-medium text-center">
          Transaction Preview Unavailable
        </div>
      </div>
      <div>
        {!isContractDeployment ? (
          <div className="font-ClashDisplay text-secondary-800 text-sm text-left">
            There was an error simulating this transaction. Be careful while
            proceeding. If this error persists, report it{' '}
            <a
              href={FIRE_SUPPORT_URL}
              target="_blank"
              className="font-ClashDisplay text-secondary-800 text-sm text-left underline hover:text-secondary-800 hover:underline"
            >
              here
            </a>
          </div>
        ) : (
          <div className="font-ClashDisplay text-secondary-800 text-sm text-left">
            This is a contract deployment. Transaction preview is unavailable
            for this transaction. If this is an error, report it{' '}
            <a
              href={FIRE_SUPPORT_URL}
              target="_blank"
              className="font-ClashDisplay text-secondary-800 text-sm text-left underline hover:text-secondary-800 hover:underline"
            >
              here
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default FailedSimulationCard;
