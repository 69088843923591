import { useState } from 'react';

interface IImageWithTextFallbackProps {
  src: string | null | undefined;
  fallbackText: string;
  className?: string;
  alt?: string;
}

const ImageWithTextFallback: React.FC<IImageWithTextFallbackProps> = ({
  src,
  fallbackText,
  className,
  alt,
}) => {
  const [didImageError, setDidImageError] = useState(false);

  const isValidSrc = !!(src && src.length);

  const StandardImage = (
    <img
      className={className}
      src={src ?? ''}
      alt={alt}
      onError={() => setDidImageError(true)}
    />
  );

  if (isValidSrc && !didImageError) {
    return StandardImage;
  } else {
    return (
      <div
        className={`border border-gray-300 p-4 rounded-full flex justify-center items-center font-Manrope font-extrabold text-sm ${className}`}
      >
        {fallbackText.toUpperCase()}
      </div>
    );
  }
};

export default ImageWithTextFallback;
