import {
  Eip712SignatureData,
  TransactionData,
} from '../helpers/interfaces/simulator.interface';
import { getFullURL } from './config';

export const getSimulatedEipTransaction = (data: TransactionData) => {
  const endPoint = 'simulator/transaction-event';
  const url = getFullURL(endPoint);

  return {
    method: 'post',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };
};

export const getSimulatedSignature = (data: Eip712SignatureData) => {
  const endPoint = 'simulator/signature';
  const url = getFullURL(endPoint);
  return {
    method: 'post',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };
};
