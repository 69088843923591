import { TrustLevelEnum } from '../enums/application.enums';
import { EIPGlobalInformation } from '../interfaces/dataTypes.interface';

export const DEFAULT_EIP_GLOBAL_INFORMATION: EIPGlobalInformation = {
  network: '',
  type: '',
  from: '',
  contractAddress: '',
  loading: true,
  error: undefined,
  rawData: '',
  downloadId: '',
  sourceUrl: undefined,
  deepSimulationData: undefined,
  isMaliciousAddress: undefined,
  securityRiskData: {
    globalTrustLevel: TrustLevelEnum.HIGH,
  },
};
