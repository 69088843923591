import ViewRawButton from '../DataTab/ViewRawButton';

interface IWalletEffectsProps {
  className?: string;
  loading: boolean;
  isSimulation?: boolean;
}

const WalletEffectsHeader: React.FC<IWalletEffectsProps> = ({
  className,
  loading,
  isSimulation = true,
}) => {
  const title = isSimulation ? 'Wallet Effects' : 'Interaction Info';

  const openExtensionDataContent = () => {
    window.parent.postMessage(
      {
        type: 'showDataTab',
      },
      '*'
    );
  };
  return (
    <div className="flex justify-between w-[371px] mt-5 mx-auto px-5 relative z-50">
      <div className="flex justify-between items-center w-full">
        <div className={`${className} font-ClashDisplay text-2xl font-medium`}>
          {title}
        </div>
        <div>
          <ViewRawButton
            onClick={() => openExtensionDataContent()}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default WalletEffectsHeader;
