const Sunset: React.FC = () => {
  return (
    <div className="bg-[#E1CFFF] py-2.5 px-4 flex flex-row items-center justify-center font-Manrope text-sm">
      <span className="mr-4">
        Important: Fire will be shutting down on April 1st as we pivot towards
        Iceberg, our non-custodial crypto trading app. We're incredibly grateful
        for your support, and invite you to stay tuned for this next chapter.
        For more details,{' '}
        <a
          href="https://www.joinfire.xyz/blog-posts/sunsetting-fire"
          target="_blank"
          className="fire-link"
        >
          click here.
        </a>
      </span>
      <a
        href="https://www.joinfire.xyz/blog-posts/sunsetting-fire"
        target="_blank"
      >
        <div className="w-8 h-8" >
          <img src="/images/open.png" className="w-8 h-8" />
        </div>
      </a>
    </div>
  );
};

export default Sunset;
